.formulaireContact {
  position: fixed;
  top: 0;
  left: 100%;
  width: 102%;
  height: 102%;
  background-color: #141414;
  z-index: 1000;
  transition: 0.5s;
  margin: 0;
  padding: 0;



  &.open {
    left: 0%;
  }

  .contact-section {
    width: 100%;
    height: 100%;

    .block--left {
      display: none;
    }

    .block--right {
      position: relative;
      top: 0;
      width: 100%;
      height: 100%;

      .croix {
        position: absolute;
        top: 7vh;
        right: 4vh;
        width: 30px;
        height: 30px;
        cursor: pointer;
        background-image: url(../assets/images/croix.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }


      h2 {
        position: relative;
        margin-bottom: 30px;
        padding-top: 100px;
        margin-left: 5%;


        &::after {
          content: "";
          position: absolute;
          bottom: -5px;
          left: 0%;
          width: 100px;
          height: 3px;
          background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
        }

      }
    }

    .contact-form {
      position: absolute;
      top: 30%;
      transform: translateX(-50%);
      left: 50%;
      width: 90%;


      &-text {
        display: block;
        font-size: 0.9em;
        width: 100%;
        box-sizing: border-box;
        margin: 16px 0;
        border: 0;
        margin-bottom: 20px;
        background-color: transparent;
        padding: 10px 10px;
        outline: none;
        color: rgb(255, 255, 255);
        transition: 0.5s;
        border-bottom: 1px solid rgb(175, 175, 175);

        &:focus {
          border-bottom: 2.5px solid white;
        }

      }

      &-btn {
        margin-top: 20px;
        float: right;
        border: 0;
        color: #fff;
        background-color: black;
        padding: 5px 10px;
        text-transform: uppercase;
        width: 150px;
        transition: 0.5s;
        border: 1px solid white;

        &:hover {
          cursor: pointer;
          background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
        }
      }

      textarea.contact-form-text {
        resize: none;
        height: 120px;

      }
    }

  }
}
















@include ld {

  .formulaireContact {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #141414;
    z-index: 500;
    transition: 0.5s;

    &.open {
      left: 0%;
    }

    .contact-section {
      width: 100%;
      height: 100%;

      .block--left {
        position: absolute;
        width: 40%;
        height: 100%;
        background-image: url(../assets/images/contact.jpg);
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
      }

      .block--right {
        position: absolute;
        left: 40%;
        width: 60%;
        height: 100%;

        .croix {
          position: absolute;
          top: 8%;
          right: 10%;
          width: 30px;
          height: 30px;
          cursor: pointer;
          background-image: url(../assets/images/croix.svg);
          background-repeat: no-repeat;
          background-size: contain;
        }


        h2 {
          position: relative;
          margin-bottom: 30px;
          margin-top: 50px;
          margin-left: 15%;

          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0%;
            width: 100px;
            height: 3px;
            background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
          }

        }
      }

      .contact-form {
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
        width: 70%;


        &-text {
          display: block;
          width: 100%;
          box-sizing: border-box;
          margin: 16px 0;
          border: 0;
          margin-bottom: 20px;
          background-color: transparent;
          padding: 10px 10px;
          outline: none;
          color: rgb(255, 255, 255);
          transition: 0.5s;
          border-bottom: 1px solid rgb(175, 175, 175);

          &:focus {
            border-bottom: 2.5px solid white;
          }

        }

        &-btn {
          margin-top: 20px;
          float: right;
          border: 0;
          color: #fff;
          background-color: black;
          padding: 5px 10px;
          text-transform: uppercase;
          width: 150px;
          transition: 0.5s;
          border: 1px solid white;

          &:hover {
            cursor: pointer;
            background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
          }
        }

        textarea.contact-form-text {
          resize: none;
          height: 120px;

        }
      }

    }
  }
}
