html {
  scroll-behavior: smooth;
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;

}

body {
  background-color: #141414;
  margin: 0;
  color: white;
  padding: 0;
  font-family: 'Montserrat';
  line-height: 1.6;
}

p {
  font-weight: 200;
  line-height: 1.8;
  font-size: 1em;
  margin-bottom: 30px;
  color: #e4e4e4;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.conteneur {
  position: relative;
  top: 0px;
  width: 90vw;
  margin: 0 auto;
  display: block;
}

.center {
  text-align: center;
  margin: auto;


  &::after {
    left: 50%;
    transform: translateX(-50%);
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logo {
  position: absolute;
  top: 40px;
  left: 5%;
  width: 170px;
  z-index: 10;
}

.progress {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.progress-container {
  background-color: rgba(95, 95, 95, 0.3);
  height: 5px;
  width: 100%;
}

.progress-bar {
  background-color: red;
  height: 5px;
  width: 0%;
}

.preloader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  overflow-y: auto;

  .logoWelcome {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    width: 50px;
    display: block;

    animation: crescendo 0.5s alternate infinite ease-in;
  }

  @keyframes crescendo {
    0% {
      transform: scale(.8);
    }

    100% {
      transform: scale(1.2);
    }

  }

  .preloader_inner {
    position: relative;
    top: 53%;
    transform: translateY(-53%);
    margin: auto;
    width: 50px;
    display: block;
    text-align: center;
    font-size: 0;
  }
}


.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;


  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.show {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}

.show2 {
  opacity: 0;
}


header {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(../assets/images/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;

  #myVideo {
    object-fit: cover;
    width: 100vw;
    height: 750px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -5;
  }



  h1 {
    position: relative;
    top: 60%;
    left: 5%;
    width: 300px;
    margin: 0;
    font-size: 2.7em;
    line-height: 1.2;
    font-weight: 700;
    font-family: 'Montserrat';

    &::after {
      content: "";
      position: absolute;
      bottom: -20px;
      left: 0%;
      width: 100px;
      height: 5px;
      background: rgba(5, 211, 164, 1);
    }



    span {
      background: rgba(5, 211, 164, 1);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  video {
    filter: blur(2px);

  }

  &::after {
    content: "";
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 100px;
    background: rgba(5, 211, 164, 1);
  }
}

.section-projets {
  margin-bottom: 50px;
}

.sec2 {
  margin-bottom: 0px;
}


section {
  position: relative;
  top: 150px;
  width: 90vw;
  margin: auto;
  margin-bottom: 80px;
  padding-top: 30px;


  .services {
    position: relative;
    display: block;
    margin: auto;
    text-align: center;
    font-size: 1.2em;
    text-transform: uppercase;

    .description {
      position: relative;
      top: 5px;
      font-weight: 100;
      font-size: 0.9em;
      color: #d1d1d1;
      text-transform: none;


    }


    &__el {
      position: relative;
      padding: 45px;
      margin: auto;
      margin-bottom: 50px;

    }

    &__el:nth-child(1) {
      &::before {
        content: url(../assets/images/www.svg);
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }


    &__el:nth-child(2) {
      &::before {
        content: url(../assets/images/digital.svg);
        position: absolute;
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__el:nth-child(3) {
      margin-bottom: 0px;

      &::before {
        content: url(../assets/images/webdesign.svg);
        position: absolute;
        top: -40px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }


  .apropos-img1 {
    position: relative;
    top: 20px;
    width: 100%;
  }

  .apropos-img2 {
    display: none;
  }
}

h2 {
  position: relative;
  width: 300px;
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0%;
    width: 100px;
    height: 3px;
    background: rgba(5, 211, 164, 1);
  }

}

h3 {
  position: relative;
  width: 300px;
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.3;

  &::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0%;
    width: 100px;
    height: 3px;
    background: rgba(5, 211, 164, 1);
  }

}


.container {
  display: flex;
  overflow-x: auto;
  padding: 8px;
  margin-top: 30px;






  &::-webkit-scrollbar {
    display: none;
  }

  .item {
    margin: 8px;
    transition: 0.3s all;
    width: 250px;
    height: 530px;
    display: block;
    margin-right: 30px;
    margin-top: 30px;

    .projet-img {
      width: 270px;
      height: 450px;
    }

    p {
      text-align: center;
      width: 250px;
      font-weight: 600;
      font-size: 1.1em;
      color: rgba(5, 211, 164, 1);

      span {
        font-size: 0.8em;
        font-weight: 400;
        position: relative;
        color: white;
        left: 10px;

        &::before {
          content: "— ";
          position: absolute;
          top: -5px;
          left: -25px;
        }
      }
    }


  }
}

.sponsoring {
  position: relative;
  top: 30px;
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-around;
  flex-wrap: wrap;

  &__el {
    margin-bottom: 20px;

    .sponso-img {
      width: 120px;

      height: 70px;
    }

  }

}



.border-gradient {
  margin: auto;
  display: block;
  width: 170px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  padding: 5px 10px;
  text-align: center;
  border-image-source: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
  text-transform: uppercase;
}

.last-section {
  height: 690px;
  width: 100%;
  background: linear-gradient(0deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
  left: 0;
  margin-bottom: 0;


  .footer--content {
    justify-content: space-around;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
  }

  .fleche {
    position: relative;
    top: 0px;
    width: 50px;
    height: 40px;
    margin: auto;
    display: block;
    background-image: url(../assets/images/fleche.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
    animation: anim 1.5s infinite ease-in-out;

    @keyframes anim {
      from {
        top: 10px;
      }

      50% {
        top: 1px;

      }

      to {
        top: 10px;
      }
    }

    &:hover {
      top: 25px;
    }
  }

  .listFooter {
    position: relative;
    top: 30px;
    text-align: center;
    font-weight: 200;
    line-height: 1.8;
    margin-bottom: 50px;

    &__el {
      &:first-of-type {
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 10px;
        font-weight: 500;

      }
    }
  }


  .listFooters {
    text-align: center;
    position: relative;
    top: 20px;
    margin: auto;
    font-weight: 200;
    line-height: 1.8;

    &__el {

      &:first-of-type {
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 10px;
      }
    }

    .footer-img {
      width: 25px;
      height: 25px;
    }

    .listicone {
      display: flex;
      justify-content: center;

      &__el {
        margin-right: 40px;
        margin-top: 10px;

        &:last-of-type {
          margin-right: 0px;

        }
      }
    }
  }

  .logoFooter {
    margin: auto;
    width: 250px;
    display: block;
    margin-top: 40px;

  }

  footer {
    position: absolute;
    bottom: 15px;
    color: #252525;
    line-height: 1.4;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
}










@include md {


  .conteneur {
    width: 70vw;
  }

  header {

    h1 {
      position: relative;
      top: 70%;
      left: 0;
      width: 400px;
    }
  }

  section {


    .services {

      .description {
        width: 450px;
      }


      &__el {
        margin-bottom: 50px;

      }


    }

    .apropos-img1 {
      width: 450px;
      margin: auto;
      display: block;
    }
  }

  .container {
    width: 90%;
    margin: auto;
  }
}































@include ld {

  .conteneur {
    width: 95vw;
  }

  .logo {
    top: 60px;
  }

  .conteneur-little {
    width: 60vw;
  }

  .grad {
    position: relative;
    top: -2px;
    width: 110px;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    padding: 2px 10px;
    text-align: center;
    border-image-source: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
    z-index: 3;
    cursor: pointer;

    a {
      z-index: 23;

    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0%;
      width: 0px;
      height: 100%;
      background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
      transition: 0.5s;
      z-index: -30;
    }

    &:hover {
      &::before {
        width: 100%;
      }

    }
  }

  header {
    z-index: 1;
    background-image: url(../assets/images/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;


    h1 {
      left: 10%;
      top: 40%;
      width: 800px;
      margin: 0;
      font-size: 4.5em;
      line-height: 1.1;
      font-weight: 700;
      font-family: 'Montserrat';



      span {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }


  section {
    left: 0%;
    width: 100vw;
    padding-bottom: 0px;

    .services {
      display: flex;
      top: 50px;
      margin: none;
      left: 0;
      width: 80%;
      align-items: flex-start;

      .description {
        position: relative;
        top: 5px;
        font-weight: 100;
        font-size: 0.8em;
        color: #b1b1b1;
        text-transform: none;

      }

      &__el {
        position: relative;
        margin-bottom: 50px;
        padding: 40px;
        width: 300px;

        &:last-child {
          margin-top: 0;
        }
      }
    }

    .dispo {
      display: grid;
      grid-template-columns: repeat(12, [col-start] 1fr);
      /*grid-template-rows: repeat(8, [row-start] 100px);*/
      grid-gap: 15px;
      height: 500px;

      p {
        margin-bottom: 20px;
      }

      &__el:first-of-type {
        grid-column: col-start 2/span 5;
      }

      &__el:nth-of-type(2) {
        grid-column: col-start 2/span 5;
      }

      &__el:nth-of-type(3) {
        grid-column: col-start 6/span 5;
      }

      &__el:last-of-type {
        grid-column: col-start 7/span 5;
      }
    }

    .citation {
      position: relative;
      left: 0%;
      width: 400px;
      font-family: 'Playfair Display';
      font-size: 1.4em;
      margin-top: 40px;

      &::before {
        content: "";
        position: absolute;
        top: 10px;
        left: -30px;
        height: 100px;
        width: 7px;
        background: linear-gradient(0deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
      }

      &::after {
        content: "- Milton Glaser";
        position: absolute;
        bottom: -20px;
        right: -0px;
        font-family: 'Montserrat';
        font-size: 0.7em;

      }
    }


    .apropos-img1 {
      position: relative;
      top: -140px;
      width: 500px;
      margin: 0;
      margin-bottom: 0;
      margin-bottom: -100px;



    }

    .apropos-img2 {
      position: absolute;
      right: 10%;
      top: 70px;
      width: 450px;
      display: block;
    }

  }

  .sec2 {
    margin-bottom: 50px;
  }

  .sec3 {
    margin-bottom: 0;

    h2 {
      position: relative;
      left: 8%;
    }
  }

  h2 {
    position: relative;
    left: 0%;
    width: 300px;
    font-size: 1.7em;
    text-transform: uppercase;

    &::after {
      content: "";
      position: absolute;
      bottom: -5px;
      left: 0%;
      width: 100px;
    }

  }

  h3 {
    position: relative;
    width: 300px;
    font-size: 1.7em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.3;

    &::after {
      content: "";
      position: absolute;
      bottom: -9px;
      left: 0%;
      width: 100px;
    }

  }


  .container {
    display: flex;
    overflow-x: auto;
    padding: 8px;
    margin-top: 30px;
    justify-content: center;


    &::-webkit-scrollbar {
      display: block;
    }

    .item {
      margin: 8px;
      transition: 0.3s all;
      width: 300px;
      margin-right: 30px;


      .project-img1 {
        position: relative;
        top: 20px;
        width: 300px;
        height: 500px;
      }

    }
  }


  .sponsoring {
    position: relative;
    top: 30px;
    display: flex;
    width: 700px;
    margin: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    &__el {
      margin-bottom: 20px;
      width: 150px;

      .sponso-img {
        width: 90px;
      }

      &:last-of-type {
        width: 180px;

        .sponso-img {
          width: 140px;
          height: 70px;
        }
      }

    }

  }

  .section-contact {
    margin-top: 120px;

    p {
      width: 500px;
      text-align: center;
      margin: auto;
      margin-top: 30px;
      margin-bottom: 30px;

    }
  }


  .fleche {
    position: relative;
    top: 40px;
    width: 50px;
    height: 40px;
    margin: auto;
    display: block;
    background-image: url(../assets/images/fleche.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
    animation: anim 1.5s infinite ease-in-out;

    @keyframes anim {
      from {
        top: 30px;
      }

      50% {
        top: 20px;

      }

      to {
        top: 30px;
      }
    }

    &:hover {
      top: 25px;
    }
  }

  .border-gradient {
    margin: auto;
    display: block;
    width: 170px;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    padding: 5px 10px;
    text-align: center;
    border-image-source: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
    text-transform: uppercase;
  }

  .last-section {
    height: 350px;
    width: 100%;
    background: linear-gradient(0deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);
    left: 0;
    padding-top: 0px;
    margin-bottom: 0;



    .footer--content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 80%;
      margin: auto;
      margin-top: 40px;
      text-align: left;
    }

    .listFooter {
      text-align: left;
    }

    .listFooters {
      margin: 0;

      .footer-img {
        width: 20px;
        height: 20px;
      }

      position: relative;
      top: 30px;
      font-weight: 200;
      line-height: 1.8;
      margin-bottom: 50px;

      &__el {

        display: flex;

        &:first-of-type {
          font-weight: 400;
          font-size: 19px;
          margin-bottom: 10px;
          font-weight: 500;

        }
      }

      .listicone {
        display: flex;

        &__el {
          margin-right: 30px;
          margin-top: 5px;

          &:hover {
            cursor: pointer;
          }
        }


      }
    }

    footer {
      position: absolute;
      bottom: 15px;
      color: #242424;
      text-align: right;
      right: 5%;

      a {
        color: #242424;
        text-decoration: underline;
      }
    }
  }

}
