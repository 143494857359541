@mixin sd {
  @media (min-width: #{$screen-sd}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}

@mixin tab {
  @media (min-height: #{$screen-tab}) {
    @content;
  }
}

@mixin ld {
  @media (min-width: #{$screen-ld}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}



@mixin rwd($screen) {
  @media (min-width: $screen + 'px'

  ) {
    @content;
  }
}
