.overlay {
  position: fixed;
  top: 0%;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  z-index: 10;
  background: linear-gradient(90deg, rgba(1, 96, 142, 1) 0%, rgba(5, 211, 164, 1) 100%);




  &.open {
    left: 0;
    transition: 0.5s;
  }
}

nav {
  position: relative;
  top: 45%;
  height: 50%;
  margin: auto;
  transform: translateY(-50%);


  &+div {
    position: absolute;
    bottom: 20px;
    width: 100%;
    font-family: Montserrat;
    font-weight: 200;
    color: white;
    font-size: 11px;
    margin-left: 30px;
    display: block;

    span:last-of-type {
      position: absolute;
      right: 60px;
    }
  }
}



.link {
  position: relative;
  top: 30%;
  height: 70%;
  padding: 0;
  z-index: 6000;
  text-transform: lowercase;
  color: black;
  width: 300px;
  margin: auto;
  font-size: 3.3em;
  text-align: center;

  &__el {
    position: relative;
    height: calc(100% / 4);
    margin-bottom: 10px;
    width: 100%;



    a {
      color: white;
      text-decoration: none;
      font-weight: 200;
    }
  }
}








.sr-only {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;

  border: 0;

  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

// outline

:focus:not(:focus-visible) {
  outline: 0;
}

button:focus {
  outline-offset: 4px;
}




.burger {
  position: fixed;
  top: 7vh;
  right: 4vh;
  width: 30px;
  height: 20px;
  padding: 0;
  z-index: 900;

  border: none;
  background-color: transparent;
  box-shadow: inset 0 -2px 0 0 white;




  &::after,
  &::before {
    position: absolute;

    content: "";
    display: block;
    height: 2px;

    background-color: white;
  }

  &::before {
    top: 0;
    left: 0;

    width: 100%;
  }

  &::after {
    top: 50%;
    right: 0;

    width: 50%;

    transform: translate(0, -50%);
  }

  &:hover {
    cursor: pointer;
  }
}

.burger--open {
  animation-name: burgerOpen;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  &::after {
    animation-name: burgerAfterOpen;
    animation-duration: 0.5s;
    animation-delay: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  &::before {
    animation-name: burgerBeforeOpen;
    animation-duration: 0.5s;
    animation-delay: 0.25s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

.burger--close {
  animation-name: burgerClose;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-direction: reverse;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;

  &::after {
    animation-name: burgerAfterClose;
    animation-duration: 0.5s;
    animation-direction: reverse;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
  }

  &::before {
    animation-name: burgerBeforeClose;
    animation-duration: 0.5s;
    animation-direction: reverse;
    animation-fill-mode: backwards;
    animation-timing-function: ease-in-out;
  }
}

@keyframes burgerOpen {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }

  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}

@keyframes burgerAfterOpen {
  0% {
    width: 50%;
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(1);
  }
}

@keyframes burgerBeforeOpen {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }

  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}

@keyframes burgerClose {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }

  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}

@keyframes burgerAfterClose {
  0% {
    width: 50%;
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }

  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(1);
  }
}

@keyframes burgerBeforeClose {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }

  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }

  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}


.links {
  display: none;
}


@include ld {
  .burger {
    position: fixed;
    top: 7vh;
    right: 8vh;
    width: 30px;
    height: 20px;
    padding: 0;
    z-index: 0;

    border: none;
    background-color: transparent;
    box-shadow: inset 0 -2px 0 0 white;
    display: none;
  }


  .links {
    display: flex;
    position: absolute;
    top: 8%;
    right: 8%;
    z-index: 200;

    .lien-cont {
      position: relative;
      top: -3px;
      border: solid 2px white;
      padding: 2px 15px;

    }

    &__el {
      margin-right: 60px;



      &::after {
        content: '';
        display: block;
        width: 0;
        height: 1px;
        background: white;
        transition: width .3s;
      }

      &:hover::after {
        width: 100%;
        //transition: width .3s;
      }
    }
  }
}
