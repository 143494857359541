@charset "UTF-8";
html {
  scroll-behavior: smooth;
}

.scroll {
  overflow-y: scroll;
  scroll-behavior: smooth;
}

body {
  background-color: #141414;
  margin: 0;
  color: white;
  padding: 0;
  font-family: "Montserrat";
  line-height: 1.6;
}

p {
  font-weight: 200;
  line-height: 1.8;
  font-size: 1em;
  margin-bottom: 30px;
  color: #e4e4e4;
}

a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.conteneur {
  position: relative;
  top: 0px;
  width: 90vw;
  margin: 0 auto;
  display: block;
}

.center {
  text-align: center;
  margin: auto;
}
.center::after {
  left: 50%;
  transform: translateX(-50%);
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logo {
  position: absolute;
  top: 40px;
  left: 5%;
  width: 170px;
  z-index: 10;
}

.progress {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.progress-container {
  background-color: rgba(95, 95, 95, 0.3);
  height: 5px;
  width: 100%;
}

.progress-bar {
  background-color: red;
  height: 5px;
  width: 0%;
}

.preloader {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #141414;
  overflow-y: auto;
}
.preloader .logoWelcome {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  width: 50px;
  display: block;
  animation: crescendo 0.5s alternate infinite ease-in;
}
@keyframes crescendo {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.2);
  }
}
.preloader .preloader_inner {
  position: relative;
  top: 53%;
  transform: translateY(-53%);
  margin: auto;
  width: 50px;
  display: block;
  text-align: center;
  font-size: 0;
}

.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
}

.show {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 0.5s ease;
}

.show2 {
  opacity: 0;
}

header {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url(../assets/images/back.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}
header #myVideo {
  object-fit: cover;
  width: 100vw;
  height: 750px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
}
header h1 {
  position: relative;
  top: 60%;
  left: 5%;
  width: 300px;
  margin: 0;
  font-size: 2.7em;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Montserrat";
}
header h1::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 0%;
  width: 100px;
  height: 5px;
  background: #05d3a4;
}
header h1 span {
  background: #05d3a4;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
header video {
  filter: blur(2px);
}
header::after {
  content: "";
  position: absolute;
  bottom: -70px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 100px;
  background: #05d3a4;
}

.section-projets {
  margin-bottom: 50px;
}

.sec2 {
  margin-bottom: 0px;
}

section {
  position: relative;
  top: 150px;
  width: 90vw;
  margin: auto;
  margin-bottom: 80px;
  padding-top: 30px;
}
section .services {
  position: relative;
  display: block;
  margin: auto;
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
}
section .services .description {
  position: relative;
  top: 5px;
  font-weight: 100;
  font-size: 0.9em;
  color: #d1d1d1;
  text-transform: none;
}
section .services__el {
  position: relative;
  padding: 45px;
  margin: auto;
  margin-bottom: 50px;
}
section .services__el:nth-child(1)::before {
  content: url(../assets/images/www.svg);
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
section .services__el:nth-child(2)::before {
  content: url(../assets/images/digital.svg);
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
section .services__el:nth-child(3) {
  margin-bottom: 0px;
}
section .services__el:nth-child(3)::before {
  content: url(../assets/images/webdesign.svg);
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
}
section .apropos-img1 {
  position: relative;
  top: 20px;
  width: 100%;
}
section .apropos-img2 {
  display: none;
}

h2 {
  position: relative;
  width: 300px;
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: 500;
}
h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0%;
  width: 100px;
  height: 3px;
  background: #05d3a4;
}

h3 {
  position: relative;
  width: 300px;
  font-size: 1.7em;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.3;
}
h3::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 0%;
  width: 100px;
  height: 3px;
  background: #05d3a4;
}

.container {
  display: flex;
  overflow-x: auto;
  padding: 8px;
  margin-top: 30px;
}
.container::-webkit-scrollbar {
  display: none;
}
.container .item {
  margin: 8px;
  transition: 0.3s all;
  width: 250px;
  height: 530px;
  display: block;
  margin-right: 30px;
  margin-top: 30px;
}
.container .item .projet-img {
  width: 270px;
  height: 450px;
}
.container .item p {
  text-align: center;
  width: 250px;
  font-weight: 600;
  font-size: 1.1em;
  color: #05d3a4;
}
.container .item p span {
  font-size: 0.8em;
  font-weight: 400;
  position: relative;
  color: white;
  left: 10px;
}
.container .item p span::before {
  content: "— ";
  position: absolute;
  top: -5px;
  left: -25px;
}

.sponsoring {
  position: relative;
  top: 30px;
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-around;
  flex-wrap: wrap;
}
.sponsoring__el {
  margin-bottom: 20px;
}
.sponsoring__el .sponso-img {
  width: 120px;
  height: 70px;
}

.border-gradient {
  margin: auto;
  display: block;
  width: 170px;
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  padding: 5px 10px;
  text-align: center;
  border-image-source: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
  text-transform: uppercase;
}

.last-section {
  height: 690px;
  width: 100%;
  background: linear-gradient(0deg, #01608e 0%, #05d3a4 100%);
  left: 0;
  margin-bottom: 0;
}
.last-section .footer--content {
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  margin: auto;
}
.last-section .fleche {
  position: relative;
  top: 0px;
  width: 50px;
  height: 40px;
  margin: auto;
  display: block;
  background-image: url(../assets/images/fleche.svg);
  background-repeat: no-repeat;
  background-size: contain;
  transition: 0.5s;
  animation: anim 1.5s infinite ease-in-out;
}
@keyframes anim {
  from {
    top: 10px;
  }
  50% {
    top: 1px;
  }
  to {
    top: 10px;
  }
}
.last-section .fleche:hover {
  top: 25px;
}
.last-section .listFooter {
  position: relative;
  top: 30px;
  text-align: center;
  font-weight: 200;
  line-height: 1.8;
  margin-bottom: 50px;
}
.last-section .listFooter__el:first-of-type {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 10px;
  font-weight: 500;
}
.last-section .listFooters {
  text-align: center;
  position: relative;
  top: 20px;
  margin: auto;
  font-weight: 200;
  line-height: 1.8;
}
.last-section .listFooters__el:first-of-type {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 10px;
}
.last-section .listFooters .footer-img {
  width: 25px;
  height: 25px;
}
.last-section .listFooters .listicone {
  display: flex;
  justify-content: center;
}
.last-section .listFooters .listicone__el {
  margin-right: 40px;
  margin-top: 10px;
}
.last-section .listFooters .listicone__el:last-of-type {
  margin-right: 0px;
}
.last-section .logoFooter {
  margin: auto;
  width: 250px;
  display: block;
  margin-top: 40px;
}
.last-section footer {
  position: absolute;
  bottom: 15px;
  color: #252525;
  line-height: 1.4;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}

@media (min-width: 550px) {
  .conteneur {
    width: 70vw;
  }

  header h1 {
    position: relative;
    top: 70%;
    left: 0;
    width: 400px;
  }

  section .services .description {
    width: 450px;
  }
  section .services__el {
    margin-bottom: 50px;
  }
  section .apropos-img1 {
    width: 450px;
    margin: auto;
    display: block;
  }

  .container {
    width: 90%;
    margin: auto;
  }
}
@media (min-width: 1000px) {
  .conteneur {
    width: 95vw;
  }

  .logo {
    top: 60px;
  }

  .conteneur-little {
    width: 60vw;
  }

  .grad {
    position: relative;
    top: -2px;
    width: 110px;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    padding: 2px 10px;
    text-align: center;
    border-image-source: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
    z-index: 3;
    cursor: pointer;
  }
  .grad a {
    z-index: 23;
  }
  .grad::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0%;
    width: 0px;
    height: 100%;
    background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
    transition: 0.5s;
    z-index: -30;
  }
  .grad:hover::before {
    width: 100%;
  }

  header {
    z-index: 1;
    background-image: url(../assets/images/background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
  }
  header h1 {
    left: 10%;
    top: 40%;
    width: 800px;
    margin: 0;
    font-size: 4.5em;
    line-height: 1.1;
    font-weight: 700;
    font-family: "Montserrat";
  }
  header h1 span {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  section {
    left: 0%;
    width: 100vw;
    padding-bottom: 0px;
  }
  section .services {
    display: flex;
    top: 50px;
    margin: none;
    left: 0;
    width: 80%;
    align-items: flex-start;
  }
  section .services .description {
    position: relative;
    top: 5px;
    font-weight: 100;
    font-size: 0.8em;
    color: #b1b1b1;
    text-transform: none;
  }
  section .services__el {
    position: relative;
    margin-bottom: 50px;
    padding: 40px;
    width: 300px;
  }
  section .services__el:last-child {
    margin-top: 0;
  }
  section .dispo {
    display: grid;
    grid-template-columns: repeat(12, [col-start] 1fr);
    /*grid-template-rows: repeat(8, [row-start] 100px);*/
    grid-gap: 15px;
    height: 500px;
  }
  section .dispo p {
    margin-bottom: 20px;
  }
  section .dispo__el:first-of-type {
    grid-column: col-start 2/span 5;
  }
  section .dispo__el:nth-of-type(2) {
    grid-column: col-start 2/span 5;
  }
  section .dispo__el:nth-of-type(3) {
    grid-column: col-start 6/span 5;
  }
  section .dispo__el:last-of-type {
    grid-column: col-start 7/span 5;
  }
  section .citation {
    position: relative;
    left: 0%;
    width: 400px;
    font-family: "Playfair Display";
    font-size: 1.4em;
    margin-top: 40px;
  }
  section .citation::before {
    content: "";
    position: absolute;
    top: 10px;
    left: -30px;
    height: 100px;
    width: 7px;
    background: linear-gradient(0deg, #01608e 0%, #05d3a4 100%);
  }
  section .citation::after {
    content: "- Milton Glaser";
    position: absolute;
    bottom: -20px;
    right: 0px;
    font-family: "Montserrat";
    font-size: 0.7em;
  }
  section .apropos-img1 {
    position: relative;
    top: -140px;
    width: 500px;
    margin: 0;
    margin-bottom: 0;
    margin-bottom: -100px;
  }
  section .apropos-img2 {
    position: absolute;
    right: 10%;
    top: 70px;
    width: 450px;
    display: block;
  }

  .sec2 {
    margin-bottom: 50px;
  }

  .sec3 {
    margin-bottom: 0;
  }
  .sec3 h2 {
    position: relative;
    left: 8%;
  }

  h2 {
    position: relative;
    left: 0%;
    width: 300px;
    font-size: 1.7em;
    text-transform: uppercase;
  }
  h2::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0%;
    width: 100px;
  }

  h3 {
    position: relative;
    width: 300px;
    font-size: 1.7em;
    text-transform: uppercase;
    font-weight: 400;
    line-height: 1.3;
  }
  h3::after {
    content: "";
    position: absolute;
    bottom: -9px;
    left: 0%;
    width: 100px;
  }

  .container {
    display: flex;
    overflow-x: auto;
    padding: 8px;
    margin-top: 30px;
    justify-content: center;
  }
  .container::-webkit-scrollbar {
    display: block;
  }
  .container .item {
    margin: 8px;
    transition: 0.3s all;
    width: 300px;
    margin-right: 30px;
  }
  .container .item .project-img1 {
    position: relative;
    top: 20px;
    width: 300px;
    height: 500px;
  }

  .sponsoring {
    position: relative;
    top: 30px;
    display: flex;
    width: 700px;
    margin: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .sponsoring__el {
    margin-bottom: 20px;
    width: 150px;
  }
  .sponsoring__el .sponso-img {
    width: 90px;
  }
  .sponsoring__el:last-of-type {
    width: 180px;
  }
  .sponsoring__el:last-of-type .sponso-img {
    width: 140px;
    height: 70px;
  }

  .section-contact {
    margin-top: 120px;
  }
  .section-contact p {
    width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .fleche {
    position: relative;
    top: 40px;
    width: 50px;
    height: 40px;
    margin: auto;
    display: block;
    background-image: url(../assets/images/fleche.svg);
    background-repeat: no-repeat;
    background-size: contain;
    transition: 0.5s;
    animation: anim 1.5s infinite ease-in-out;
  }
  @keyframes anim {
    from {
      top: 30px;
    }
    50% {
      top: 20px;
    }
    to {
      top: 30px;
    }
  }
  .fleche:hover {
    top: 25px;
  }

  .border-gradient {
    margin: auto;
    display: block;
    width: 170px;
    border: 2px solid;
    border-image-slice: 1;
    border-width: 2px;
    padding: 5px 10px;
    text-align: center;
    border-image-source: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
    text-transform: uppercase;
  }

  .last-section {
    height: 350px;
    width: 100%;
    background: linear-gradient(0deg, #01608e 0%, #05d3a4 100%);
    left: 0;
    padding-top: 0px;
    margin-bottom: 0;
  }
  .last-section .footer--content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    margin-top: 40px;
    text-align: left;
  }
  .last-section .listFooter {
    text-align: left;
  }
  .last-section .listFooters {
    margin: 0;
    position: relative;
    top: 30px;
    font-weight: 200;
    line-height: 1.8;
    margin-bottom: 50px;
  }
  .last-section .listFooters .footer-img {
    width: 20px;
    height: 20px;
  }
  .last-section .listFooters__el {
    display: flex;
  }
  .last-section .listFooters__el:first-of-type {
    font-weight: 400;
    font-size: 19px;
    margin-bottom: 10px;
    font-weight: 500;
  }
  .last-section .listFooters .listicone {
    display: flex;
  }
  .last-section .listFooters .listicone__el {
    margin-right: 30px;
    margin-top: 5px;
  }
  .last-section .listFooters .listicone__el:hover {
    cursor: pointer;
  }
  .last-section footer {
    position: absolute;
    bottom: 15px;
    color: #242424;
    text-align: right;
    right: 5%;
  }
  .last-section footer a {
    color: #242424;
    text-decoration: underline;
  }
}
.overlay {
  position: fixed;
  top: 0%;
  left: 100%;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  z-index: 10;
  background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
}
.overlay.open {
  left: 0;
  transition: 0.5s;
}

nav {
  position: relative;
  top: 45%;
  height: 50%;
  margin: auto;
  transform: translateY(-50%);
}
nav + div {
  position: absolute;
  bottom: 20px;
  width: 100%;
  font-family: Montserrat;
  font-weight: 200;
  color: white;
  font-size: 11px;
  margin-left: 30px;
  display: block;
}
nav + div span:last-of-type {
  position: absolute;
  right: 60px;
}

.link {
  position: relative;
  top: 30%;
  height: 70%;
  padding: 0;
  z-index: 6000;
  text-transform: lowercase;
  color: black;
  width: 300px;
  margin: auto;
  font-size: 3.3em;
  text-align: center;
}
.link__el {
  position: relative;
  height: calc(100% / 4);
  margin-bottom: 10px;
  width: 100%;
}
.link__el a {
  color: white;
  text-decoration: none;
  font-weight: 200;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

:focus:not(:focus-visible) {
  outline: 0;
}

button:focus {
  outline-offset: 4px;
}

.burger {
  position: fixed;
  top: 7vh;
  right: 4vh;
  width: 30px;
  height: 20px;
  padding: 0;
  z-index: 900;
  border: none;
  background-color: transparent;
  box-shadow: inset 0 -2px 0 0 white;
}
.burger::after, .burger::before {
  position: absolute;
  content: "";
  display: block;
  height: 2px;
  background-color: white;
}
.burger::before {
  top: 0;
  left: 0;
  width: 100%;
}
.burger::after {
  top: 50%;
  right: 0;
  width: 50%;
  transform: translate(0, -50%);
}
.burger:hover {
  cursor: pointer;
}

.burger--open {
  animation-name: burgerOpen;
  animation-duration: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.burger--open::after {
  animation-name: burgerAfterOpen;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
.burger--open::before {
  animation-name: burgerBeforeOpen;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.burger--close {
  animation-name: burgerClose;
  animation-duration: 0.25s;
  animation-delay: 0.5s;
  animation-direction: reverse;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
}
.burger--close::after {
  animation-name: burgerAfterClose;
  animation-duration: 0.5s;
  animation-direction: reverse;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
}
.burger--close::before {
  animation-name: burgerBeforeClose;
  animation-duration: 0.5s;
  animation-direction: reverse;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
}

@keyframes burgerOpen {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }
  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}
@keyframes burgerAfterOpen {
  0% {
    width: 50%;
  }
  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(1);
  }
}
@keyframes burgerBeforeOpen {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }
  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }
  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}
@keyframes burgerClose {
  0% {
    box-shadow: inset 0 -2px 0 0 var(--colorBlack);
  }
  100% {
    box-shadow: inset 0 -2px 0 0 transparent;
  }
}
@keyframes burgerAfterClose {
  0% {
    width: 50%;
  }
  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  25% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  50% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  75% {
    width: 100%;
    transform: translate(0, -50%) rotate(0) scaleX(1);
  }
  100% {
    width: 100%;
    transform: translate(0, -50%) rotate(45deg) scaleX(1);
  }
}
@keyframes burgerBeforeClose {
  0% {
    transform: translateY(0) rotate(0) scaleX(1);
  }
  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  25% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  50% {
    top: 0;
    transform: translateY(0) rotate(0) scaleX(1);
  }
  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }
  75% {
    top: 50%;
    transform: translateY(-50%) rotate(0) scaleX(1);
  }
  100% {
    top: 50%;
    transform: translateY(-50%) rotate(-45deg) scaleX(1);
  }
}
.links {
  display: none;
}

@media (min-width: 1000px) {
  .burger {
    position: fixed;
    top: 7vh;
    right: 8vh;
    width: 30px;
    height: 20px;
    padding: 0;
    z-index: 0;
    border: none;
    background-color: transparent;
    box-shadow: inset 0 -2px 0 0 white;
    display: none;
  }

  .links {
    display: flex;
    position: absolute;
    top: 8%;
    right: 8%;
    z-index: 200;
  }
  .links .lien-cont {
    position: relative;
    top: -3px;
    border: solid 2px white;
    padding: 2px 15px;
  }
  .links__el {
    margin-right: 60px;
  }
  .links__el::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: white;
    transition: width 0.3s;
  }
  .links__el:hover::after {
    width: 100%;
  }
}
.formulaireContact {
  position: fixed;
  top: 0;
  left: 100%;
  width: 102%;
  height: 102%;
  background-color: #141414;
  z-index: 1000;
  transition: 0.5s;
  margin: 0;
  padding: 0;
}
.formulaireContact.open {
  left: 0%;
}
.formulaireContact .contact-section {
  width: 100%;
  height: 100%;
}
.formulaireContact .contact-section .block--left {
  display: none;
}
.formulaireContact .contact-section .block--right {
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
}
.formulaireContact .contact-section .block--right .croix {
  position: absolute;
  top: 7vh;
  right: 4vh;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background-image: url(../assets/images/croix.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.formulaireContact .contact-section .block--right h2 {
  position: relative;
  margin-bottom: 30px;
  padding-top: 100px;
  margin-left: 5%;
}
.formulaireContact .contact-section .block--right h2::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0%;
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
}
.formulaireContact .contact-section .contact-form {
  position: absolute;
  top: 30%;
  transform: translateX(-50%);
  left: 50%;
  width: 90%;
}
.formulaireContact .contact-section .contact-form-text {
  display: block;
  font-size: 0.9em;
  width: 100%;
  box-sizing: border-box;
  margin: 16px 0;
  border: 0;
  margin-bottom: 20px;
  background-color: transparent;
  padding: 10px 10px;
  outline: none;
  color: white;
  transition: 0.5s;
  border-bottom: 1px solid #afafaf;
}
.formulaireContact .contact-section .contact-form-text:focus {
  border-bottom: 2.5px solid white;
}
.formulaireContact .contact-section .contact-form-btn {
  margin-top: 20px;
  float: right;
  border: 0;
  color: #fff;
  background-color: black;
  padding: 5px 10px;
  text-transform: uppercase;
  width: 150px;
  transition: 0.5s;
  border: 1px solid white;
}
.formulaireContact .contact-section .contact-form-btn:hover {
  cursor: pointer;
  background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
}
.formulaireContact .contact-section .contact-form textarea.contact-form-text {
  resize: none;
  height: 120px;
}

@media (min-width: 1000px) {
  .formulaireContact {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #141414;
    z-index: 500;
    transition: 0.5s;
  }
  .formulaireContact.open {
    left: 0%;
  }
  .formulaireContact .contact-section {
    width: 100%;
    height: 100%;
  }
  .formulaireContact .contact-section .block--left {
    position: absolute;
    width: 40%;
    height: 100%;
    background-image: url(../assets/images/contact.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
  }
  .formulaireContact .contact-section .block--right {
    position: absolute;
    left: 40%;
    width: 60%;
    height: 100%;
  }
  .formulaireContact .contact-section .block--right .croix {
    position: absolute;
    top: 8%;
    right: 10%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-image: url(../assets/images/croix.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }
  .formulaireContact .contact-section .block--right h2 {
    position: relative;
    margin-bottom: 30px;
    margin-top: 50px;
    margin-left: 15%;
  }
  .formulaireContact .contact-section .block--right h2::after {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 0%;
    width: 100px;
    height: 3px;
    background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
  }
  .formulaireContact .contact-section .contact-form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    width: 70%;
  }
  .formulaireContact .contact-section .contact-form-text {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    border: 0;
    margin-bottom: 20px;
    background-color: transparent;
    padding: 10px 10px;
    outline: none;
    color: white;
    transition: 0.5s;
    border-bottom: 1px solid #afafaf;
  }
  .formulaireContact .contact-section .contact-form-text:focus {
    border-bottom: 2.5px solid white;
  }
  .formulaireContact .contact-section .contact-form-btn {
    margin-top: 20px;
    float: right;
    border: 0;
    color: #fff;
    background-color: black;
    padding: 5px 10px;
    text-transform: uppercase;
    width: 150px;
    transition: 0.5s;
    border: 1px solid white;
  }
  .formulaireContact .contact-section .contact-form-btn:hover {
    cursor: pointer;
    background: linear-gradient(90deg, #01608e 0%, #05d3a4 100%);
  }
  .formulaireContact .contact-section .contact-form textarea.contact-form-text {
    resize: none;
    height: 120px;
  }
}